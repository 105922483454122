import options from './ShopifyButtonOptions';
import {storefrontAccessToken} from './ShopifyButtonOptions';

/*<![CDATA[*/
(function () {
    const scriptURL = 'https://sdks.shopifycdn.com/buy-button/latest/buy-button-storefront.min.js';
    if (window.ShopifyBuy) {
        if (window.ShopifyBuy.UI) {
            ShopifyBuyInit();
        } else {
            loadScript();
        }
    } else {
        loadScript();
    }

    function loadScript() {
        const script = document.createElement('script');
        script.async = true;
        script.src = scriptURL;
        (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(
            script);
        script.onload = ShopifyBuyInit;
    }

    function ShopifyBuyInit() {
        const client = ShopifyBuy.buildClient({
            domain: 'wella-buy-button.myshopify.com',
            storefrontAccessToken,
        });
        ShopifyBuy.UI.onReady(client).then((ui) => {
            ui.createComponent('cart', {
                moneyFormat: '%24%7B%7Bamount%7D%7D',
                options: options
            });
        });
    }
})()
/*]]>*/

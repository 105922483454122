export const storefrontAccessToken = '6800bb56c49553d4330477abc16618f5';
const options = {
    "product": {
        "styles": {
          "product": {
            "@media (min-width: 601px)": {
              "max-width": "calc(25% - 20px)",
              "margin-left": "20px",
              "margin-bottom": "50px"
            }
          },
          "button": {
            ":hover": {
              "background-color": "#4e9bac"
            },
            "background-color": "#4e9bac",
            ":focus": {
              "background-color": "#4e9bac"
            }
          }
        },
        "contents": {
          "img": false,
        "button": false,
          "buttonWithQuantity": true,
          "title": false,
          "price": true
        },
        "text": {
          "button": "Add to cart"
        }
      },
      "productSet": {
        "styles": {
          "products": {
            "@media (min-width: 601px)": {
              "margin-left": "-20px"
            }
          }
        }
      },
      "modalProduct": {
        "contents": {
          "img": false,
          "imgWithCarousel": true,
          "button": false,
          "buttonWithQuantity": true
        },
        "styles": {
          "product": {
            "@media (min-width: 601px)": {
              "max-width": "100%",
              "margin-left": "0px",
              "margin-bottom": "0px"
            }
          },
          "button": {
            ":hover": {
              "background-color": "#4e9bac"
            },
            "background-color": "#4e9bac",
            ":focus": {
              "background-color": "#4e9bac"
            }
          }
        },
        "text": {
          "button": "Add to cart"
        }
      },
      "option": {
        "styles": {
          "label": {
            "font-family": "Arial, sans-serif"
          },
          "select": {
            "font-family": "Arial, sans-serif"
          }
        }
      },
      "cart": {
        "styles": {
          "button": {
            ":hover": {
              "background-color": "#4e9bac"
            },
            "background-color": "#4e9bac",
            ":focus": {
              "background-color": "#4e9bac"
            }
          }
        },
        "text": {
          "total": "Subtotal (CAD)",
          "notice": "Shipping is FREE for orders valued $75 or more.  Taxes calculated at checkout.",
          "button": "Checkout"
        }
      },
      "toggle": {
        "styles": {
          "toggle": {
            "background-color": "#4e9bac",
            ":hover": {
              "background-color": "#4e9bac"
            },
            ":focus": {
              "background-color": "#4e9bac"
            }
          }
        }
      }
}
export default options
